import React from "react";

const CalendarVariant = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 0.25C13.4142 0.25 13.75 0.585786 13.75 1V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V3.75H8.5C8.08579 3.75 7.75 3.41421 7.75 3C7.75 2.58579 8.08579 2.25 8.5 2.25H12.25V1C12.25 0.585786 12.5858 0.25 13 0.25ZM0.25 9C0.25 8.58579 0.585786 8.25 1 8.25H19C19.4142 8.25 19.75 8.58579 19.75 9V18C19.75 19.5188 18.5188 20.75 17 20.75H3C1.48122 20.75 0.25 19.5188 0.25 18V9ZM1.75 9.75V18C1.75 18.6904 2.30964 19.25 3 19.25H17C17.6904 19.25 18.25 18.6904 18.25 18V9.75H1.75Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 3.75C2.30964 3.75 1.75 4.30964 1.75 5V9C1.75 9.41421 1.41421 9.75 1 9.75C0.585786 9.75 0.25 9.41421 0.25 9V5C0.25 3.48122 1.48122 2.25 3 2.25H5C5.41421 2.25 5.75 2.58579 5.75 3C5.75 3.41421 5.41421 3.75 5 3.75H3Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 0.25C5.41421 0.25 5.75 0.585786 5.75 1V5C5.75 5.41421 5.41421 5.75 5 5.75C4.58579 5.75 4.25 5.41421 4.25 5V1C4.25 0.585786 4.58579 0.25 5 0.25Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.75 3C15.75 2.58579 16.0858 2.25 16.5 2.25H17C18.5188 2.25 19.75 3.48122 19.75 5V9C19.75 9.41421 19.4142 9.75 19 9.75C18.5858 9.75 18.25 9.41421 18.25 9V5C18.25 4.30964 17.6904 3.75 17 3.75H16.5C16.0858 3.75 15.75 3.41421 15.75 3Z"
      fill="currentColor"
    />
  </svg>
);

export default CalendarVariant;
