// Import the necessary modules
import React from "react";
import { StoryFn, Meta } from "@storybook/react";
import CkIcon, { CkIconProps, CkIconNames, CkAvailableIcons } from "./index";
import "./CkIcon.stories.css";

// Define the story
export default {
  title: "general/CkIcon",
  component: CkIcon,
  parameters: {
    layout: "centered",
  },
  tags: ["autodocs"],
} as Meta;

const copyToClipboard = (iconName: CkIconNames) => {
  if (navigator.clipboard && iconName) {
    navigator.clipboard.writeText(iconName);
  } else {
    alert("Your browser doesn't support Clipboard API");
  }
};

const Template: StoryFn<CkIconProps> = ({ name, ...args }) => (
  <div className={"icons-container"}>
    <div className="icon-container" onClick={() => copyToClipboard(name)}>
      <CkIcon name={name} {...args} />
      <p className="icon-name">{name}</p>
    </div>
  </div>
);

// Define a template for the component states
const DisplayTemplate: StoryFn<CkIconProps> = (args) => (
  <div className={"icons-container"}>
    {CkAvailableIcons.map((iconName) => (
      <div className="icon-container" onClick={() => copyToClipboard(iconName)}>
        <CkIcon name={iconName} {...args} />
        <p className="icon-name">{iconName}</p>
      </div>
    ))}
  </div>
);

// Now, create a story using the template
export const Default = Template.bind({});
Default.args = {
  name: "add-image",
};

// Now, create a story using the template
export const Display = DisplayTemplate.bind({});
Display.args = {};
